import "@onlion/components/dist/index.css";

function App() {
  return (
    <div className="app">
      <div className={`w-xs m-auto p-8 space-y-2`}>
        <h1 className="font-extrabold text-4xl">Denne side er rykket</h1>
        <p>Du kan nu logge på det sammenlagte OnLion-panel.</p>
        <p>
          <a
            className="text-blue-700 underline"
            href="https://www.admin.onlion.io/auth"
          >
            Klik her for at logge ind på det nye panel
          </a>
        </p>
        <p className="text-gray-500">&copy; OnLion - vedligehold for alle</p>
      </div>
    </div>
  );
}

export default App;
